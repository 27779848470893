import React from 'react'
import styled from 'styled-components'

import CheckSvg from 'assets/icons/Check.svg'
import { breakpoints } from 'components/ui-kit/globalValue'

const CheckSvgStyled = styled(CheckSvg)`
    flex: 0 0 auto;
    padding-right: 10px;
`
const Text = styled.span`
    padding-right: 35px;
`
const Box = styled.li`
    display: flex;
    padding: 10px 0;
    @media (min-width: ${breakpoints.tablet}) {
        display: block;
        width: 350px;
        height: auto;
    }
    @media (min-width: ${breakpoints.desktop}) {
        width: 500px;
    }
`

export const CheckMarkElement = (props) => (
    <Box>
        <CheckSvgStyled />
        <Text>{props.children}</Text>
    </Box>
)
